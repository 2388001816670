@import '../../app/styles/color';

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;

  p {
    padding: 0;
    margin: 0;
    margin-bottom: 50px;
  }

  .hidden {
    transform: translateY(20px);
    opacity: .001;
    transition: transform .3s cubic-bezier(0.215, 0.61, 0.355, 1), opacity .2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .active {
    transform: translateY(0);
    opacity: .999;
  }

  .title {
    transition-delay: .3s;
  }

  .subtitle {
    transition-delay: .35s;
  }

  .icon {
    transition-delay: .1s;
  }

  .media-button {
    margin-top: 90px;
    opacity: 1;

    &.hidden {
      transform: scale(0) translateY(20px);
      transform-origin: center;
      transition: transform .2s .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &.active {
      transform: scale(1) translateY(0);
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 40px;
    top: 80px;
    position: relative;
    align-items: center;
    height: 40px;
  }

  .soundcloud {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $primary-font-color;
    position: relative;
    height: 30px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      opacity: .01;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      top: -5px;
      left: -7px;
      padding: 3px;
      transition: opacity .3s;
    }

    &:focus {
      outline: none;
    }
  }

  .soundcloud:focus::before {
    opacity: 0.1;
    border: solid;
  }
}
