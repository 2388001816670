@import '../../app/styles/color';

.icon {
  fill: $list-hover;

  &--back {
    margin-right: 3px;
  }
}

.icon-button {
  cursor: pointer;
  border-radius: 50%;
  border: 0;
  width: 35px;
  height: 35px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .3s;
  position: relative;

  &:hover {
    background-color: $primary-icon-bg-color;
  }

  &:active {
    background-color: $list-press;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    opacity: .01;
    width: 100%;
    height: 100%;
    border: solid 2px $border-color;
    border-radius: 50px;
    transition: opacity .3s;
    box-shadow: 0px 0px 0px 3px rgba(123, 177, 243, 0.33);
    top: -2px;
    left: -2px;
  }

  &--high-light {
    svg {
      fill: $primary-highlight-color;
      stroke: $secondary-highlight-color;
      stroke-width: 5px;
    }
  }
}


.icon-button:focus {
  outline: 0;
}

.icon-button:focus::before {
  opacity: .57;
}
